.inner-container{
    gap: 1.5rem;
    background: #ec9569;
    padding: 2rem;
    border-radius: 10px;
    border: 6px solid #ec9569;
    text-align: center;
}

.inner-container .primaryText{
    color: white;
    font-weight: 600;
}

.inner-container .secondaryText{
    color: rgba(255, 255, 255, 0.587)
}

.inner-container .button{
    background: #ec9569;
    bordeR: 2px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
}